import Link from 'next/link'
import React from 'react'
import Styles from './styles.module.scss'
import { motion, useCycle } from 'framer-motion'
// languages
import en from '@/public/locales/en/common.json';
import pt from '@/public/locales/pt/common.json';
import { useRouter } from 'next/router';
import Image from 'next/image';

import defaultImage from '@/assets/img/placeholder.webp'
import { fetchDataUserInfo } from '@/utils/fetchDataAxios';
import MenuMobileFooter from '../MenuMobileFooter/MenuMobileFooter';
import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import X from '@/assets/img/XIcon.webp'

type Props = {
    name: string,
    contrast?: boolean,
    profileImageSrc: string,
    isAdmin: boolean,
    envs: { [key: string]: string }
    handleDiv2Click?: any
    div2Open?: any
    email?: string
}

const UserOptionsMobile = ({
    name,
    contrast = false,
    profileImageSrc,
    isAdmin,
    envs,
    handleDiv2Click,
    div2Open,
    email
}: Props) => {

    const [dataUser, setDataUser] = React.useState<any>();
    // const [admin, setAdmin] = React.useState<boolean>(false);
    // const [openMenu, setopenMenu] = React.useState<boolean>(false)

    const router = useRouter();
    const { locale } = router;

    React.useEffect(() => {
        const accessToken = localStorage.getItem('accessToken')
        let logado = JSON.parse(localStorage.getItem('logado')!)
        if (logado === 'undefined') {

            fetchDataUserInfo(accessToken, setDataUser, router, envs.NEXT_PUBLIC_GET_USER_INFO)
        } else {

            setDataUser(logado)
        }
    }, [router])


    function handleWalletState(state: number) {
        console.log(state)
        localStorage.setItem('walletState', String(state))
    }
    const t = locale === 'en' ? en : pt;

    const handleWhiteTheme = () => {
        return contrast ? Styles.white : undefined
    }

    const handleMenuState = () => {
        return div2Open ? Styles.open : undefined
    }

    const handleMenuToggle = () => {

        handleDiv2Click()
        // if (open) {
        //   return setOpen(false)
        // }
        // return setOpen(true)
    }
    const upperCaseName = dataUser?.name?.toUpperCase();
    const userNameInitial = upperCaseName?.slice(0, 1)
    return (

        <motion.div
            className={`${Styles.user} ${handleWhiteTheme()}`}
            onClick={() => { handleMenuToggle() }}
        >
            <p className={Styles.user__phrase}>{t.hello} {name.split(' ')[0]}</p>
            <Image
                alt='Profile Picture'
                src={profileImageSrc ? profileImageSrc : `/images/alphabet/${userNameInitial}.svg`}
                width={40}
                height={40}
                className={Styles.user__picture}
                style={div2Open ? { opacity: 0 } : { opacity: 1 }}
                onClick={() => { handleMenuToggle() }}
            />
            <motion.div animate={div2Open ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }} transition={{ duration: 0.4, bounce: true }} className={`${Styles.user__menu} ${handleMenuState()}`}>
                <div onClick={() => { handleMenuToggle() }} className={Styles.closeButton}>
                    <Image src={X} alt='X' style={{ width: '60%', height: '60%' }} />
                </div>
                <div className={Styles.headerContainer}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                        <Image
                            alt='Profile Picture'
                            id='userImageMobile'
                            src={profileImageSrc ? profileImageSrc : `/images/alphabet/${userNameInitial}.svg`}
                            width={40}
                            height={40}
                            className={Styles.user__picture2}
                            onClick={() => { handleMenuToggle() }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>

                        <div style={{ display: 'flex', }}>

                            <p style={{ paddingRight: 6, fontWeight: 400, margin: 0, }}>
                                {t.hello}
                            </p>
                            <p style={{ margin: 0 }}>
                                {name.split(' ')[0]}
                            </p>
                        </div>
                        <u style={{ fontSize: 12, fontWeight: 200, color: '#b4b2b2' }}>
                            {email}
                        </u>
                    </div>


                </div>

                <motion.ul className={Styles.user__list} animate={div2Open ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }} transition={{ duration: 0.4, bounce: true }}>
                    <Link href="/perfil" locale={locale}>
                        <li className={Styles.user__item} style={{ color: '#FFF', fontWeight: 600 }} onClick={() => { localStorage.setItem('buyCNLTSession', 'true') }}>
                            Comprar CNLT
                        </li>
                    </Link>
                    <Link href="/perfil" locale={locale}>
                        <li className={Styles.user__item} style={{ color: '#FFF', fontWeight: 600 }} onClick={() => { localStorage.setItem('buy', 'true') }}>
                            Investir em projetos
                        </li>
                    </Link>
                    <Link href="/perfil#history" locale={locale}>
                        <li className={Styles.user__item}>
                            Histórico de transações
                        </li>
                    </Link>
                    <Link href="/perfil" locale={locale}>
                        <li className={Styles.user__item} onClick={(() => {
                            handleWalletState(1)
                        })}>
                            Carteira
                        </li>
                    </Link>
                    <Link href="/perfil#" locale={locale}>
                        <li className={Styles.user__item} onClick={() => { localStorage.setItem('saque', 'true') }}>
                            Sacar
                        </li>
                    </Link>
                    <Link href="mailto:contato@blocklize.tech" locale={locale}>
                        <li className={Styles.user__item}>
                            Ajuda
                        </li>
                    </Link>
                    {isAdmin && (
                        <Link href={dataUser?.isAdmin ? "/admin" : "/notfound"} locale={locale}>
                            <li className={Styles.user__item}>
                                Controle Admin
                            </li>
                        </Link>
                    )}

                    <Link href='/logout' locale={locale}>
                        <li className={Styles.user__item}>
                            {t.disconnect}

                        </li>
                    </Link>
                </motion.ul>
                <MenuMobileFooter div1Open={div2Open} />
            </motion.div>
        </motion.div>
    )
}

export default UserOptionsMobile